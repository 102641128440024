import React, { useLayoutEffect } from "react";
import queryString from "query-string";
import window from "global/window";
import SEO from "@src/components/SEO";

// Universal link page, just redirects to the teacher app if user does not have native app

const IndexPage = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { c, st, school } = parsedLocation;

  useLayoutEffect(() => {
    if (c && st) {
      window.location.href = `https://teach.classdojo.com/#/classes/${c}/story/${st}`;
    } else if (school && st) {
      window.location.href = `https://teach.classdojo.com/#/schools/${school}/story/${st}`;
    } else if (c) {
      window.location.href = `https://teach.classdojo.com/#/classes/${c}/story`;
    } else if (school) {
      window.location.href = `https://teach.classdojo.com/#/schools/${school}/story`;
    } else {
      window.location.href = `https://teach.classdojo.com/#/`;
    }
  }, [c, st, school]);

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default IndexPage;
